<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-body>
        <small class="mr-50">New Product or Idea</small>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer ref="formSubmitted">

      <!-- form -->
      <b-form class="mt-2"  @submit.prevent="productSubmitted">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Name"
              label-for="idea-name"
              class="mb-2"
            >
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
              <b-form-input
                id="idea-name"
                v-model="ideaName"
                :state="errors.length > 0 ? false:null"
                placeholder="Name of the Product or Idea"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
          <validation-provider
                #default="{ errors }"
                name="industries"
                rules="required"
              >
              <b-form-group
                label="Industries"
                label-for="idea-Industries"
                class="mb-2"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                id="idea-Industries"
                v-model="ideaIndustries"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="industries"
                placeholder="What industries does the idea impact"
                label="text"
              />

                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group
                label="Problem Statement"
                label-for="statement"
              >
                <validation-provider
                  #default="{ errors }"
                  name="problem statement"
                  rules="required"
                >
                  <b-form-input
                    id="statement"
                    v-model="statement"
                    placeholder="Short creative statement about the problem (150 charaters)"
                    :state="errors.length > 0 && errors.length < 151 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="Status"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <v-select
                id="blog-edit-category"
                v-model="blogEdit.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOption"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group
              label="Solution"
              label-for="description"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="solution"
                  rules="required"
                >
              <quill-editor
                id="description"
                v-model="description"
                :options="snowOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Specialization / Area of Focus"
              label-for="specialization"
            >
              <validation-provider
                #default="{ errors }"
                name="specialization"
              >
                <v-select taggable multiple 
                  id="specialization"
                  placeholder="List all areas or specializations"
                  :state="null"
                  :options=[]
                  v-model="tags"
                >
                <span slot="no-options">Type to add a new specialization</span>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Status"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <v-select
                id="blog-edit-category"
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Featured Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="featuredImage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                  <b-card-text class="my-50">
                  </b-card-text>
                  <div class="d-inline-block">
                    <b-form-file
                      ref="refInputEl"
                      v-model="productFile"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose file"
                      @input="fileImageInput"
                    />
                  </div>
                </b-media-body>
              </b-media>
              <small v-if="productFileError" class="text-danger"> An image is required</small>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 float-right"
              type="submit"
            >
              Create Product or Idea
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
    <b-modal id="image-crop-modal" width="500" ok-only ok-variant="secondary" ok-title="Cancel">
      <b-card>
        <b-card-text>
          <VueCropper v-show="productFile" ref="cropper" alt="Source Image"></VueCropper>
        </b-card-text>
        <b-button class="primary" @click="saveImage()">Crop</b-button>
      </b-card>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getAuth,} from "firebase/auth";
import { getFirestore, addDoc, updateDoc, doc, arrayUnion, collection, serverTimestamp } from 'firebase/firestore';
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

const auth = getAuth()
const db = getFirestore()
const storage = getStorage()
const user = auth.currentUser

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueCropper,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormInvalidFeedback,
    required
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      featuredImage: null,
      description: null,
      statement: null,
      ideaIndustries: null,
      ideaName: null,
      productFile: null,
      productFileError: false,
      status: 'Public',
      tags:[],
      statusOption: ['Hidden', 'Public'],
      snowOption: {
        placeholder: 'Describe the solution to the problem along with pain points this solves and how much it may cost...',
        modules: {
          toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean'],
                ['link'] // 'image','video']                                 
          ],
        },
        theme: 'snow',
      },
      industries: [
        'Accounting',
        'Airlines/Aviation',
        'Alternative Dispute Resolution',
        'Alternative Medicine',
        'Animation',
        'Apparel & Fashion',
        'Architecture & Planning',
        'Arts & Crafts',
        'Automotive',
        'Aviation & Aerospace',
        'Banking',
        'Biotechnology',
        'Broadcast Media',
        'Building Materials',
        'Business Supplies & Equipment',
        'Capital Markets',
        'Chemicals',
        'Civil Engineering',
        'Commercial Real Estate',
        'Computer & Network Security',
        'Computer Games',
        'Computer Hardware',
        'Computer Networking',
        'Computer Software',
        'Construction',
        'Consumer Electronics',
        'Consumer Goods',
        'Consumer Services',
        'Cosmetics',
        'Dairy',
        'Defense & Space',
        'Design',
        'E-Learning',
        'Education Management',
        'Electrical & Electronic Manufacturing',
        'Entertainment',
        'Environmental Services',
        'Events Services',
        'Executive Office',
        'Facilities Services',
        'Farming',
        'Financial Services',
        'Fine Art',
        'Fishery',
        'Food & Beverages',
        'Food Production',
        'Fundraising',
        'Furniture',
        'Gambling & Casinos',
        'Glass',
        'Government Administration',
        'Government Relations',
        'Graphic Design',
        'Health',
        'Higher Education',
        'Hospital & Health Care',
        'Hospitality',
        'Human Resources',
        'Import & Export',
        'Individual & Family Services',
        'Industrial Automation',
        'Information Services',
        'Information Technology & Services',
        'Insurance',
        'International Affairs',
        'International Trade and Development',
        'Internet',
        'Investment Banking',
        'Judiciary',
        'Law Enforcement',
        'Law Practice',
        'Legal Services',
        'Legislative Office',
        'Leisure',
        'Libraries',
        'Logistics & Supply Chain',
        'Luxury Goods & Jewelry',
        'Machinery',
        'Management Consulting',
        'Maritime',
        'Market Research',
        'Marketing & Advertising',
        'Mechanical or Industrial Engineering',
        'Media Production',
        'Medical Device',
        'Medical Practice',
        'Mental Health Care',
        'Military',
        'Mining & Metals',
        'Mobile Games',
        'Motion Pictures & FIlm',
        'Museums & Institutions',
        'Music',
        'Nanotechnology',
        'Newspapers',
        'Non-profit Organization Management',
        'Oil & Energy',
        'Online Media',
        'Outsourcing/Offshoring',
        'Package/Freight Delivery',
        'Packaging & Containers',
        'Paper & Forest Products',
        'Performing Arts',
        'Pharmaceuticals',
        'Philanthropy',
        'Photography',
        'Plastics',
        'Political Organization',
        'Primary/Secondary Education',
        'Printing',
        'Professional Training & Coaching',
        'Program Development',
        'Public Policy',
        'Public Relations & Communications',
        'Public Safety',
        'Publishing',
        'Railroad Manufacture',
        'Ranching',
        'Real Estate',
        'Recreational Facilities & Services',
        'Religious Institution',
        'Renewables & Environment',
        'Research',
        'Restaurants',
        'Retail',
        'Security & Investigations',
        'Semiconductors',
        'Shipbuilding',
        'Sporting Goods',
        'Sports',
        'Staffing & Recruiting',
        'Supermarkets',
        'Telecommunications',
        'Textiles',
        'Think Tanks',
        'Tobacco',
        'Translation & Localization',
        'Transportation/Trucking/Railroad',
        'Utilities',
        'Venture Capital & Private Equity',
        'Veterinary',
        'Warehousing',
        'Wine & Spirits',
        'Wireless',
        'Writing & Editing',
      ],
    }
  },
  created() {
    
  },
  methods: {
    async productSubmitted() {

      // TODO add a busy indicator
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.formSubmitted.validate()
      if(!this.featuredImage){ this.productFileError = true; return }
      if (!validateForm) return
      
      // Create the product 
      const newProduct = {name: this.ideaName.trim(), uid:user.uid, username:user.displayName, image:this.featuredImage, status: this.status, industries: this.ideaIndustries, tags:this.tags, statement: this.statement, description:this.description, comments:0, created_time: serverTimestamp() }
      const productRecord = await addDoc(collection(db, "products"), newProduct)

      console.log(productRecord)

      // Update profile
      await updateDoc(doc(db, "profiles", user.displayName ), {ideaPage:true, products:arrayUnion({productName: this.ideaName.trim(), id:productRecord.id}) }, { merge: true })
      
      this.$router.replace('/ideas/list').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '`New Idea or Product Created',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      });
    },
    saveImage(){
      try {
        const cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          // Upload file
          const metadata = { contentType: this.mime_type }
          const storageRef = ref(storage, 'products/' + user.displayName + (this.ideaName)?this.ideaName:'' )
          const uploadTask = await uploadBytes(storageRef, blob, metadata)

          // Get the download URL
          const newImageUrl = await getDownloadURL(storageRef)
          this.featuredImage = newImageUrl
          this.productFileError = false

          // Close Dialog
          this.$bvModal.hide('image-crop-modal')
        }, this.mime_type)

      } catch (e) {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Profile Image Upload Error `,
            icon: 'ImageIcon',
            variant: 'danger',
            text: 'There was an issue uploading your profile image please try again.',
          },
        })
      } finally {
        this.processing = false;
      }
    },
    fileImageInput(file) {
      if (file && file.name) {
        this.processing = true
        this.$bvModal.show('image-crop-modal')

        // Read file
        const fr = new FileReader()
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          // this is to load image on the UI
          this.mime_type = file.type
          this.previewImage = fr.result
          this.$refs.cropper.replace(this.previewImage)
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
